import React, {useEffect, useState} from "react";
import {useMutation, useQuery} from "@apollo/react-hooks";
import Card from "antd/es/card";
import Button from "antd/es/button";
import Spin from "antd/es/spin";
import message from "antd/es/message";
import Col from "antd/es/grid/col";
import Form from "antd/es/form";
import Input from "antd/es/input";
import Row from "antd/es/grid/row";
import { parsePhoneNumberFromString } from 'libphonenumber-js'
import {GET_PARENT, UPDATE_PARENT} from "./graphqlRequests";
import DefaultDatePicker from "../utils/DefaultDatePicker";
import moment from "moment";
import Popconfirm from "antd/es/popconfirm";
import {ResetPasswordButton} from "./passwordResetButton";
import {NotificationsStatus} from "./notificationsStatus";

export default function Parent({history, location, match}) {
    const {id: parentId} = match.params;

    const query = useQuery(GET_PARENT, {variables: {id: Number.parseInt(parentId)}, skip: !parentId});
    const [updateParent] = useMutation(UPDATE_PARENT);
    const {
        loading, error, data = {
            parent: {
                name: "", lastName: "", surName: "", birthday: "", id: null, phoneNumber: ""
            }
        }
    } = query;
    const [parent, setParent] = useState({...data.parent});
    const [saving, setSaving] = useState(false);

    useEffect(() => {
        const phoneNum = parsePhoneNumber(data.parent.phoneNumber)
        setParent({
            ...data.parent, 
            phoneNumber: phoneNum ? phoneNum.formatInternational() : data.parent.phoneNumber 
        })
    }, [query]);

    const saveParent = (customProps) => {
        let messageSaving = message.loading("Сохранение", 0);
        setSaving(true);
        updateParent({variables: {...parent, ...customProps, id: Number.parseInt(parent.id)}})
            .then(({data: {updateParent: {id}}}) => history.goBack())
            .then(() => setSaving(false))
            .then(() => {
                setTimeout(messageSaving, 1);
                message.success(`${parent.lastName} ${parent.name} сохранен`)
            })
    };

    const changeField = ({target: {id, value}}) => {
        if (value) {
            value = value[0].toUpperCase() + value.slice(1);
        }

        setParent({...parent, [id]: value});
    };

    const changePhoneNumber = ({target: {id, value}}) => {
        const phoneNumber = parsePhoneNumber(value);
        
        setParent({...parent, [id]: phoneNumber ? phoneNumber.formatInternational() : value });
    };

    const parsePhoneNumber = (value = "+ 7") =>  value ? parsePhoneNumberFromString(value, 'RU') : value;

    const isFormValid = parent.lastName && parent.name && parent.phoneNumber;

    return (
        <Spin spinning={loading || saving}>
            <Card size="small"
                  title={parent.isDeleted ? (<span style={{color: "red"}}>Удаленный Представитель</span>) : "Представитель"}
                  extra={<ResetPasswordButton phoneNumber={parent.phoneNumber} />}
            >
                <Col>
                    <Row gutter={[8, 8]}>
                        <Col span={8}>
                            <Form.Item label="Фамилия" required
                                       validateStatus={!parent.lastName ? "error" : "success"} hasFeedback
                                       help={!parent.lastName ? "Необходимо заполнить" : null}
                            >
                                <Input placeholder="Фамилия" required value={parent.lastName} id="lastName" onChange={changeField}/>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item label="Имя" required
                                       validateStatus={!parent.name ? "error" : "success"} hasFeedback
                                       help={!parent.name ? "Необходимо заполнить" : null}
                            >
                                <Input placeholder="Имя" required value={parent.name} id="name" onChange={changeField}/>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item label="Отчество"
                                       validateStatus={!parent.surName ? "warning" : "success"} hasFeedback
                                       help={!parent.surName ? "Рекомендуется заполнить" : null}
                            >
                                <Input placeholder="Отчество" value={parent.surName} id="surName" onChange={changeField}/>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={[8,8]}>
                        <Col span={8}>
                            <Form.Item label="Номер телефона"
                                       validateStatus={!parent.phoneNumber ? "error" : "success"} hasFeedback
                                       help={!parent.phoneNumber ? "Необходимо заполнить" : null}
                            >
                                <Input placeholder="+7 " value={parent.phoneNumber} id="phoneNumber" onChange={changePhoneNumber}/>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item label="Дата рождения"
                                       validateStatus={!parent.birthday ? "warning" : "success"} hasFeedback
                                       help={!parent.birthday ? "Рекомендуется заполнить" : null}
                            >
                                <DefaultDatePicker onChange={(mom) => changeField({target: {id: "birthday", value: mom.format("YYYY-MM-DD")}})} 
                                    value={!parent.birthday ? null : moment(parent.birthday)}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item label="Уведомления в мобильном приложении">
                                <NotificationsStatus phoneNumber={parent.phoneNumber} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={[8,8]}>
                        <Col span={24}>
                            <Form.Item label="Описание">
                                <Input.TextArea id="description" value={parent.description}
                                                onChange={changeField}/>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={[8,8]}>
                        <Col span={12}>
                            <Button type="primary" style={{width: '100%'}} onClick={() => saveParent()} disabled={!isFormValid}>
                                {isFormValid ? "Сохранить" : "Для сохранения заполните обязательные параметры"}
                            </Button>
                        </Col>
                        <Col span={12}>
                            <Popconfirm
                                title="Вы уверены, что хотите удаить эту запись?"
                                onConfirm={() => saveParent({isDeleted: true})}
                                okText="Да"
                                cancelText="Отмена"
                            >
                                <Button type="danger" style={{width: '100%'}} disabled={!isFormValid || (parent.isDeleted || false)}>
                                    Удалить
                                </Button>
                            </Popconfirm>
                        </Col>
                    </Row>
                </Col>
            </Card>
        </Spin>
    )
}
