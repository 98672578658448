import React, {useEffect} from "react";
import { Layout } from "antd";
import LeftSlider from "../leftSlider";
import PrivateRoutes from "./routers";
import {
    Switch,
    Route,
    useLocation
} from "react-router-dom";
import LoginPage from '../../../components/loginPage';
import * as H from "history";
import {sendPageLoadTiming} from "../../../analytics";

export default function AppContent():React.ReactElement {
    let location = useLocation<{background:H.Location}>();
    const {background} = location.state || {background: {pathname: "/"}};

    return (
            <Switch location={background || location}>
                <Route exact path={"/login"} component={LoginPage} />
                <AppLayout />
            </Switch>
    )
}

export function AppLayout():React.ReactElement {
    const startMoment = Date.now();
    useEffect(() => {
        sendPageLoadTiming(window.location.pathname + window.location.search, startMoment)
    })
    return (
        <Layout style={{ minHeight: '100vh' }}>
            <LeftSlider />
            <Layout>
                {/* <AppHeader /> */}
                <Layout.Content style={{ margin: 2 }}>
                    <div style={{ padding: 16, background: '#fff', minHeight: "100%" }}>
                        <PrivateRoutes />
                    </div>
                </Layout.Content>
            </Layout>
        </Layout>
    )
}