import React from "react";
import Row from "antd/es/grid/row";
import Col from "antd/es/grid/col";
import ChildCard from "./child_card";
import Parents from "../parents/parents";
import Lessons from "../lessons/lessons";

export default function ChildPage({history, match}) {
    const {params: {id}} = match;

    return (
        <div>
            <Row gutter={[8, 8]}>
                <Col span={12}>
                    <Row gutter={[8, 8]}>
                        <Col>
                            <ChildCard childId={id} navigation={history}/>
                        </Col>
                    </Row>
                </Col>
                <Col span={12}>
                    <Parents childId={id} history={history}/>
                </Col>
                <Col span={24}>
                    <Lessons childId={id}/>
                </Col>
            </Row>
        </div>
    )
}