import React, { useState } from "react";
import Form from "antd/es/form";
import Input from "antd/es/input";
import Icon from "antd/es/icon";
import Button from "antd/es/button";
import Card from "antd/es/card";
import Row from "antd/es/grid/row";
import Col from "antd/es/grid/col";
import { useLocation, useHistory } from "react-router-dom";
import { useAuth } from "../../auth/useAuth";

function LoginPage({ form }) {
    const {login} = useAuth();
    return (
        <Row>
            <Row>
                <div style={{ height: 250 }} />
            </Row>
            <Row>
                <Col span={9} />
                <Col span={6}>
                    <Card style={{ maxWidth: 520 }} title={"Авторизация"}>
                        <LoginForm form={form} login={login} />
                    </Card>
                </Col>
                <Col span={9} />
            </Row>
        </Row>
    )
}

function LoginForm({ form, login }) {
    const [error, setError] = useState(null);
    const { getFieldDecorator } = form;
    const location = useLocation();
    const history = useHistory();
    const { from } = location.state || { from: { pathname: "/" } };

    const handleSubmit = e => {
        e.preventDefault();
        form.validateFields((err, values) => {
            if (!err) {
                login(values.username, values.password)
                    .then(() => {
                        history.replace("/");
                    })
                    .catch((error) => {
                        if (error.response) {
                            console.log(error.response.data);
                            console.log(error.response.status);
                            console.log(error.response.headers);
                            setError("Неправильные логин или пароль!")
                        }
                    });
            }
        });
    };

    return (
        <Form onSubmit={handleSubmit} className="login-form">
            <Form.Item>
                {getFieldDecorator('username', {
                    rules: [{ required: true, message: 'Введите имя пользователя!' }],
                })(
                    <Input
                        prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
                        placeholder="Имя пользователя"
                    />,
                )}
            </Form.Item>
            <Form.Item>
                {getFieldDecorator('password', {
                    rules: [{ required: true, message: 'Введите пароль!' }],
                })(
                    <Input
                        prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
                        type="password"
                        placeholder="Пароль"
                    />,
                )}
            </Form.Item>
            <Form.Item>
                <Button type="primary" htmlType="submit" className="login-form-button">
                    Войти
                </Button>
            </Form.Item>
            {
                !error ? null : (
                    <Form.Item>
                        <span style={{ color: "red" }}>{error}</span>
                    </Form.Item>
                )
            }
        </Form>
    )

}

export default Form.create({ name: 'normal_login' })(LoginPage)