import React, {useState} from "react";
import {useQuery} from "@apollo/react-hooks";
import Button from "antd/es/button";
import Spin from "antd/es/spin";
import DefaultTable from "../utils/DefaultTable";
import moment from "moment";
import {GET_NOTIFICATIONS} from "./queries.graphql";
import NotificationFactoryModal from "./notificationFactoryModal";
import LessonModal from "../lessons/modal";

export default function Notifications({history, location, match}) {
    const [searchFilters, setSearchFilters] = useState({
        searchText: '',
        searchedColumn: '',
    });
    const [lessonModal, setLessonModal] = useState({visible: false, setIdNull: false, lesson: {id: null}});
    const [modal, setModalProps] = useState({visible: false});
    const query = useQuery(GET_NOTIFICATIONS);

    const {loading, data = {filteredNotifications: []}, refetch:refetchNotifications} = query;
    const cols = [
        {
            title: "Дата & Время",
            dataIndex: "created",
            render: (val) => moment(val).format("DD.MM.YY HH:mm")
        },
        {
            title: "Вид уведомления",
            customFilter: true,
            onFilter: (value, record) => record.method === value,
            dataIndex: "method",
            filters: [
                { text: "СМС", value: "SMS"},
                { text: "Push в приложении", value: "MOBILE_APP_PUSH"},
                { text: "Уведомление от клиента", value: "WEB_APP_PUSH"},
            ],
            render: (method) => {
                if (method === "SMS") return "СМС";
                if (method === "MOBILE_APP_PUSH") return "Push в приложении";
                if (method === "WEB_APP_PUSH") return "Уведомление от клиента";

                return "Другое";
            }
        },
        {
            title: "Статус",
            dataIndex: "status",
            render: (status) => <Button type={"link"}>{status}</Button>
        },
        {
            title: "Сообщение",
            dataIndex: "message",
            render: (val, record) => {
                if (val === "Занятие отменено клиентом") {
                    const lessonId = record.props.find(item => item.key === "lessonId");
                    const clientId = record.props.find(item => item.key === "clientId");

                    const lessonLink = lessonId !== undefined
                        ? <a onClick={() => setLessonModal({
                            ...lessonModal,
                            visible: true,
                            lesson: {id: lessonId.value}
                        })}>Занятие</a>
                        : "Занятие"

                    const clientLink = lessonId !== undefined
                        ? <a onClick={() => history.push(`/child/${clientId.value}`)}>клиентом</a>
                        : "клиентом"

                    return (<span>{lessonLink} отменено {clientLink}</span>)
                }
                return val
            }
        },
        {
            title: "Получатель",
            dataIndex: "recipient",
            render: (user) => {
                if (user === null) return (<Button type={"link"}>НЕТ ДАННЫХ</Button>)

                return (<Button type={"link"}>{user.lastName} {user.name} {user.surName || ""}</Button>)
            }
        }
    ];

    const dataSource = data.filteredNotifications;

    return (
        <Spin spinning={loading}>
            <DefaultTable setSearchFilters={setSearchFilters} searchFilters={searchFilters}
                          columns={cols} dataSources={dataSource} tableKey="notifications" tableTitle="Уведомления"
                          selectable={false}
                          footer={() => {
                             return (<Button type="link" onClick={() => {setModalProps({...modal, visible: true})}}>Создать новое</Button>)
                          }}
            />
            <NotificationFactoryModal props={modal} setProps={setModalProps} refetch={refetchNotifications}/>
            <LessonModal {...{lessonModal}} lessonCallback={() => {
                setLessonModal({...lessonModal, visible: false, setIdNull: false});
                refetchNotifications();
            } }/>
        </Spin>
    )
}
