import React, {useState} from 'react';
import { ApolloProvider } from '@apollo/react-hooks';
import { getApolloClient } from "./utils/connections";
import {BrowserRouter, useHistory} from "react-router-dom";
import AppContent from "./components/app/content";
import ReactGA from 'react-ga';
import 'antd/dist/antd.css'
import whyDidYouRender from "@welldone-software/why-did-you-render";
import {AuthProvider, useAuth} from './auth/useAuth';
import {Button, notification, Popconfirm} from "antd";
import SockJsClient from 'react-stomp';
import {getAccessToken} from "./auth/tokenUtils";
import LessonModal from "./components/lessons/modal";

whyDidYouRender(React, {
    collapseGroups: true,
  logOnDifferentValues: false,
  trackHooks: false,
  });

ReactGA.initialize('UA-157762439-1', {
    debug: false
});

export default function App() {
    return (
        <ApolloProvider client={getApolloClient()}>
            <AuthProvider>
                <BrowserRouter >
                    <NotificationFactory/>
                    <AppContent />
                </BrowserRouter>
            </AuthProvider>
        </ApolloProvider>
    );
}

const NotificationFactory = () => {
    const history = useHistory();
    const [lessonModal, setLessonModal] = useState({visible: false, setIdNull: false, lesson: {id: null}});
    const onConnected = () => console.info("WS Connected")
    const onMessageReceived = (msg) => {
        const lessonLink = msg.dataMap.lessonId !== undefined
            ? <a onClick={() => setLessonModal({
                ...lessonModal,
                visible: true,
                lesson: {id: msg.dataMap.lessonId}
            })}>Занятие</a>
            : "Занятие"

        const clientLink = msg.dataMap.specialistId !== undefined
            ? <a onClick={() => history.push(`/child/${msg.dataMap.clientId}`)}> {msg.dataMap.clientFio} </a>
            : "клиентом"

        const specLink = msg.dataMap.clientId !== undefined
            ? <a onClick={() => history.push(`/specialist/${msg.dataMap.specialistId}`)}> {msg.dataMap.specialistFio} </a>
            : " специалиста"
        const desc = (<span>{lessonLink} у {specLink} отменено клиентом {clientLink}</span>)
        notification.info({
            message: msg.title, description: desc, duration: 12,
            btn: (<Popconfirm title={"Вы уверены?"} okText={"Да"} cancelText={"Нет"}
                              onConfirm={() => localStorage.setItem("showNotifications", "false")}>
                <Button type={"danger"}>Больше не показывать</Button>
            </Popconfirm>)
        })
    }

    return (<>
            <SockJsClient
                url={'/ws-message'}
                topics={['/topic/message']}
                onConnect={onConnected}
                onDisconnect={() => console.log("Notifications Disconnected!")}
                // onMessage={msg => onMessageReceived(msg)}
                onMessage={msg => (localStorage.getItem("showNotifications") || "true") === "true" ? onMessageReceived(msg) : () => {} }
                debug={true}
            />
        <LessonModal {...{lessonModal}} lessonCallback={() => {
            setLessonModal({...lessonModal, visible: false, setIdNull: false});
        } }/>
    </>
    )
}