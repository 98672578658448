import React, {useState} from "react";
import Row from "antd/es/grid/row";
import {useQuery} from "@apollo/react-hooks";
import Spin from "antd/es/spin";
import Button from "antd/es/button";
import {useHistory, useLocation, Link} from "react-router-dom";
import DefaultTable from "../utils/DefaultTable";
import {Col, Divider} from "antd";
import Tag from "antd/es/tag";
import {GET_ALL_CHILDREN_WITH_PARENTS} from "../lessons/graphqlRequests";
import Input from "antd/es/input";
import moment from "moment";

export default function ChildrenPage() {
    const history = useHistory();
    const location = useLocation();
    const [pagination, setPagination] = useState({page: 1, count: 11})
    const [searchFilters, setSearchFilters] = useState({
        searchText: '',
        searchedColumn: '',
    });
    const getChildren = useQuery(GET_ALL_CHILDREN_WITH_PARENTS, {
        variables: {
            ...searchFilters,
            page: pagination.page - 1, //Spring считает страницы с 0, а ant design с 1 :(
            count: pagination.count
        }
    });
    const {loading, data = {children: []}} = getChildren;
    const cols = [
        {
            title: "ФИО",
            dataIndex: "fio",
        },
        {
            title: "Дата рождения",
            dataIndex: "birthday",
            render: (_, {birthday}) => {
                return birthday === null ? "Нет данных" : moment(birthday).format("DD.MM.YYYY");
            },
            customFilter: true
        },
        {
            title: "Представители",
            customFilter: true,
            render: (record, row) => {
                const {parents} = row;
                return parents.map(parent => (
                    <Tag color="blue" key={`parent_${parent.id}`}>
                        {parent.name} {parent.surName} {parent.phoneNumber}
                    </Tag>))
            }
        },
        {
            title: "Действия",
            render: row => ([
                <Link key={"action_go_to_card"} to={`/child/${row.id}`} id={`row_link_card_${row.id}`}>
                    <Button id={`row_btn_card_${row.id}`} type="link">Карточка</Button>
                </Link>,
                <Divider key={"divider"} type="vertical"/>,
                (<Button type="link" key={"action_show_timetable"}
                         id={`row_btn_schedule_${row.id}`}
                         onClick={() => history.push({
                             pathname: `/schedule/child/${row.id}`,
                             state: {
                                 background: location,
                             }
                         })}>
                    Расписание
                </Button>),
            ])
        },
    ];

    const dataSources = data.children.map(item => ({
        ...item,
        fio: `${item.lastName} ${item.name} ${item.surName || ""}`
    }));

    const tableChange = (pagination, filters, sorter, extra = {currentDataSource: []}) => {
        setPagination({page: pagination.current, count: pagination.pageSize})
    }
    return (
        <Row gutter={[16, 16]}>
            <Col span={24}>
                <Input width={"100%"} placeholder={"Кого будем искать? (Формат ввода: Фамилия Имя Отчество)"}
                       onChange={e => {
                           setSearchFilters({searchedColumn: "fio", searchText: e.target.value})
                       }}
                />
            </Col>
            <Spin spinning={loading}>
                <Col span={24}>
                    <DefaultTable selectable={false}
                                  searchFilters={searchFilters}
                                  setSearchFilters={setSearchFilters}
                                  dataSources={dataSources}
                                  pagination={{total: 2000}}
                                  columns={cols} id={"schedule_table"}
                                  tableKey="child_"
                                  footer={() => (
                                      <Button id={"add_btn"} type="link"
                                              onClick={() => history.push("/child/")}>Добавить</Button>)}
                                  onChange={tableChange}
                    />
                </Col>
            </Spin>
        </Row>
    )
}